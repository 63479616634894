import React from 'react'
import { Row, Col } from 'antd'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from '@/components'
import { Box, useMediaQuery } from '@mui/material'
import { Footer, Header, SEO } from '@/components'
import { StaticImage } from 'gatsby-plugin-image'
import Slogan from '@/components/Slogan'
import { goToCSP } from '@/utils'

export default (props) => {
  console.log(999, props)
  const {} = useTranslation()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const howToApplyLocale = JSON.parse(props.data.howToApplyLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')

  const {
    cspTitleMex,
    discriptionMex,
    keyBebefits,
    keyBebefitsList,
    whoCanApplyTitle,
    whoCanApplyDiscription1,
    whoCanApplyDiscription2,
    whoCanApplyList = [],
  } = pageLocale
  const { title, cspMexlist } = howToApplyLocale

  return (
    <>
      <SEO
        title={seoLocale.channelServicePartner.title}
        description={seoLocale.channelServicePartner.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Careers at iMile, iMile jobs, Work for iMile, iMile delivery job, job opportunities iMile, Work at iMile'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/cooperate/CSP' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/1c763be06e4165a95c46d50a3039cf28/d5f9a/banner.webp'
        />
        <meta
          property='og:title'
          content='Unleash Your Potential: Partner with iMile for Global CSP Success'
        />
        <meta
          property='og:description'
          content='We provide opportunities for both businesses and individuals to start their ventures within our network, and receive the support of our advanced technology and productivity.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {/* banner*/}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            height: ['100%', '100%', '555px', '705px'],
            mt: ['55px', '55px', '90px'],
            width: 1,
          },
        }}
      >
        {isPc ? (
          <StaticImage
            src='../../../static/images/cspBanner.png'
            alt='iMile ECommerce Delivery App'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
        ) : (
          // <Box component='img' src='/images/cspBanner.png' className='bg-img'></Box>
          <StaticImage
            src='../../../static/images/cspBanner.png'
            alt='iMile ECommerce Delivery App'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
          // <Box component='img' src='/images/cspBanner.jpg' className='bg-img'></Box>
        )}
        <Slogan
          slogan={pageLocale.cspBannerTitle}
          sloganDesc={pageLocale.cspBannerSubTitle}
          btnprops={
            <Box
              sx={{
                p: ['4px 30px', '4px 30px', '6px 52px'],
                mt: ['10px', '20px'],
                display: 'inline-block',
                bgcolor: '#FFF303',
                borderRadius: ['19px', '19px', '26px'],
                fontSize: ['16px', '16px', '26px'],
                fontWeight: 'bold',
                cursor: 'pointer',
                '&:active': {
                  opacity: '0.8',
                },
              }}
              onClick={() => goToCSP()}
            >
              {pageLocale.合作请求}
            </Box>
          }
          sx={{
            position: ['absolute', 'absolute'],
            top: ['60px', '189px'],
            left: ['20px', '82px'],
          }}
          fontSize1={['16px', '26px', '42px', '55px']}
        />
      </Box>
      {isPc ? (
        <Box>
          <Box sx={{ margin: '60px 80px' }}>
            <Box
              sx={{
                fontSize: '36px',
                fontWeight: 'bold',
                lineHeight: '52px',
                textAlign: 'center',
                color: '#000',
                marginBottom: '30px',
              }}
            >
              {cspTitleMex}
            </Box>
            <Box>{discriptionMex}</Box>
          </Box>
          <Box
            sx={{
              padding: '60px 80px 80px',
              '.card-show': {
                marginBottom: '50px',
              },
              '.ant-col-8': {
                padding: '0 10px',
              },
            }}
          >
            <Box sx={{ lineHeight: '32px', fontSize: '28px', fontWeight: 'bold' }}>
              {whoCanApplyTitle}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '16px', marginTop: '30px' }}>
              {whoCanApplyDiscription1}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '16px', marginBottom: '52px' }}>
              {whoCanApplyDiscription2}
            </Box>
            <Row>
              {whoCanApplyList.slice(0, -1).map((item) => (
                <Col span={8} className='card-show'>
                  <Box
                    sx={{
                      position: 'relative',
                      minHeight: '330px',
                      height: '100%',
                      borderRadius: ['20px', '32px'],
                      padding: '33px 24px 24px 30px',
                      bgcolor: '#fff',
                      mb: ['30px'],
                      mx: '10px',
                      boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.06)',
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        border: '4px solid #095ef7',
                        width: '100%',
                        minHeight: '330px',
                        height: '100%',
                        borderRadius: ['20px', '32px'],
                        left: '10px',
                        top: '-9px',
                        zIndex: -1,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 'bold',
                        marginBottom: '19px',
                      }}
                    >
                      {item.title}
                    </Box>
                    <Box sx={{ fontSize: '16px', lineHeight: '28px' }}>{item.discription}</Box>
                  </Box>
                </Col>
              ))}
            </Row>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ margin: '3rem 1.6rem' }}>
            <Box
              sx={{
                fontSize: '1.4rem',
                fontWeight: 'bold',
                lineHeight: '52px',
                textAlign: 'center',
                color: '#000',
                marginBottom: '1.6rem',
              }}
            >
              {cspTitleMex}
            </Box>
            <Box>{discriptionMex}</Box>
          </Box>
          {/* <Box
            sx={{
              background: 'rgba(6, 44, 255, 0.04)',
              padding: '2.5rem 1.5rem',
            }}
          >
            <Box
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
                lineHeight: '32px',
                marginBottom: '2rem',
              }}
            >
              {keyBebefits}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {keyBebefitsList.map((item) => (
                <Box
                  sx={{
                    width: '100%',
                    img: {
                      marginRight: '1rem',
                    },
                    span: {
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      lineHeight: '28px',
                      color: '#000',
                    },
                    '&:nth-of-type(1)': {
                      borderBottom: '1px solid #D2D5DE',
                      paddingBottom: '1rem',
                    },
                    '&:nth-of-type(2)': {
                      paddingTop: '1rem',
                    },
                  }}
                >
                  <Box component='img' src={item.path}></Box>
                  <span>{item.title}</span>
                  <Box>{item.discription}</Box>
                </Box>
              ))}
            </Box>
          </Box> */}
          <Box
            sx={{
              padding: '3rem 1.6rem',
              '.card-show': {
                marginBottom: '2rem',
              },
              '.ant-col-8': {
                padding: '0 10px',
              },
            }}
          >
            <Box sx={{ lineHeight: '32px', fontSize: '1.3rem', fontWeight: 'bold' }}>
              {whoCanApplyTitle}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '0.9rem', marginTop: '30px' }}>
              {whoCanApplyDiscription1}
            </Box>
            <Box sx={{ lineHeight: '28px', fontSize: '0.9rem', marginBottom: '52px' }}>
              {whoCanApplyDiscription2}
            </Box>
            <Row>
              {whoCanApplyList.slice(0, -1).map((item) => (
                <Col span={24} className='card-show'>
                  <Box
                    sx={{
                      position: 'relative',
                      minHeight: '330px',
                      height: '100%',
                      borderRadius: ['20px', '32px'],
                      padding: '33px 24px 24px 30px',
                      bgcolor: '#fff',
                      mb: ['30px'],
                      mx: '10px',
                      boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.06)',
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        border: '4px solid #095ef7',
                        width: '100%',
                        height: '100%',
                        borderRadius: ['20px', '32px'],
                        left: '10px',
                        top: '-9px',
                        zIndex: -1,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '24px',
                        lineHeight: '32px',
                        fontWeight: 'bold',
                        marginBottom: '19px',
                      }}
                    >
                      {item.title}
                    </Box>
                    <Box sx={{ fontSize: '16px', lineHeight: '28px' }}>{item.discription}</Box>
                  </Box>
                </Col>
              ))}
            </Row>
          </Box>
        </Box>
      )}

      {isPc ? (
        <Box sx={{ background: '#F6F8FB', padding: '40px 0 60px' }}>
          <Box
            sx={{
              textAlign: 'center',
              lineHeight: '52px',
              fontSize: '38px',
              fontWeight: '700',
              paddingTop: '40px',
              color: '#000',
            }}
          >
            {title}
          </Box>
          {cspMexlist.map((item, idx) => (
            <Box sx={{ padding: '15px 70px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: '56px',
                    height: '56px',
                    position: 'relative',
                    img: {
                      width: '56px',
                    },
                  }}
                >
                  <Box component='img' src='/images/step.svg' />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '20%',
                      left: '30%',
                      color: '#fff',
                      fontSize: '26px',
                      fontWeight: 'bold',
                    }}
                  >{`0${idx + 1}`}</Box>
                </Box>
                <Box
                  sx={{
                    lineHeight: '32px',
                    fontSize: '24px',
                    fontWeight: '700',
                    color: '#000',
                    padding: '0 30px',
                  }}
                >
                  {item.title}
                </Box>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', height: 'max-content' }}>
                <Box
                  sx={{
                    width: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      top: '15px',
                      width: '2px',
                      height: '100%',
                      background: '#7582C7',
                      margin: '0 27px',
                      opacity: idx + 1 === cspMexlist.length ? 0 : 0.2,
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    flex: '1',
                    background: '#fff',
                    margin: '0 10px',
                    padding: '14px 20px',
                    borderRadius: '12px',
                  }}
                >
                  {item.discription}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ background: '#F6F8FB', padding: '0 0 2rem' }}>
          <Box
            sx={{
              textAlign: 'center',
              lineHeight: '52px',
              fontSize: '1.6rem',
              fontWeight: '700',
              paddingTop: '2rem',
              color: '#000',
            }}
          >
            {title}
          </Box>
          {cspMexlist.map((item, idx) => (
            <Box sx={{ padding: '1rem 1.5rem' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: '56px',
                    height: '56px',
                    position: 'relative',
                    img: {
                      width: '56px',
                    },
                  }}
                >
                  <Box component='img' src='/images/step.svg' />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '20%',
                      left: '30%',
                      color: '#fff',
                      fontSize: '1.3rem',
                      fontWeight: 'bold',
                    }}
                  >{`0${idx + 1}`}</Box>
                </Box>
                <Box
                  sx={{
                    lineHeight: '32px',
                    fontSize: '1rem',
                    fontWeight: '700',
                    color: '#000',
                    padding: '0 1.5rem',
                  }}
                >
                  {item.title}
                </Box>
              </Box>
              <Box sx={{ width: '100%', display: 'flex', height: 'max-content' }}>
                <Box
                  sx={{
                    width: '56px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      top: '15px',
                      width: '2px',
                      height: '100%',
                      background: '#7582C7',
                      margin: '0 27px',
                      opacity: idx + 1 === cspMexlist.length ? 0 : 0.2,
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    flex: '1',
                    background: '#fff',
                    margin: '0 10px',
                    padding: '14px 20px',
                    borderRadius: '12px',
                  }}
                >
                  {item.discription}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <Box
        sx={{
          background: '#F6F8FB',
          display: 'flex',
          justifyContent: 'center',
          pb: ['2rem', '60px'],
        }}
      >
        <Box
          sx={{
            '--btn-height': ['36px', '52px', '52px', '66px', '76px'],
            height: 'var(--btn-height)',
            lineHeight: 'var(--btn-height)',
            borderRadius: ['38px'],
            p: ['0 20px', '0 40px'],
            fontSize: ['18px', '26px'],
            fontWeight: 700,
            color: '#fff',
            bgcolor: '#095ef7',
            cursor: 'pointer',
            display: 'inline-block',
            textAlign: 'center',
          }}
          onClick={() => goToCSP()}
        >
          {pageLocale.合作请求}
        </Box>
      </Box>

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["csp"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
    howToApplyLocale: locale(ns: { eq: "howToApply" }, language: { eq: $language }) {
      data
    }
  }
`
