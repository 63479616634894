import { Box } from '@mui/material'
import React from 'react'

export const Slogan = (props) => {
  const {
    slogan,
    sloganDesc,
    top,
    fontSize1,
    fontSize2,
    maxWidth1,
    maxWidth2,
    left,
    btnprops, // 首页传个按钮 dom
  } = props
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          position: 'absolute',
          left: left || ['15vw', '10vw'],
          top: [
            `calc(${top?.[0] || '60px'})`,
            `calc(348px - ${top?.[1] || '180px'})`,
            `calc(500px - ${top?.[2] || '287px'})`,
          ],
          zIndex: 1,
          ...(props.sx || {}),
        }}
      >
        <Box
          sx={{
            fontSize: fontSize1 || ['26px', '42px', '55px'],
            color: '#fff',
            maxWidth: maxWidth1 || ['284px', '450px', '596px'],
            fontWeight: 800,
          }}
        >
          {slogan}
        </Box>
        <Box
          sx={{
            fontSize: fontSize2 || ['12px', '14px', '21px'],
            color: '#fff',
            maxWidth: maxWidth2 || ['258px', '427px', '618px'],
            mt: ['2px', '10px'],
          }}
        >
          {sloganDesc}
        </Box>
        {btnprops}
      </Box>
    </>
  )
}

export default Slogan
